import React from "react";

import "./Card.css";
import { Produto } from "../../interfaces/produto/Produto";
import { formatPrice } from "../../../util/format";

interface CardProps {
  produto: Produto;
  click: (isOpen: boolean, produto: Produto) => void;
}

const maxLengthName = 28;
const maxLengthDescription = 70;

export default function Card(props: CardProps) {
  return (
    <div className="card" onClick={() => props.click(true, props.produto)} >
      <strong>
        {props.produto.nome.substr(0, maxLengthName)}
        {props.produto.nome.length > maxLengthName ? "..." : ""}
      </strong>
      <span>
        {props.produto.informacaoProduto
          ? props.produto.informacaoProduto?.substr(0, maxLengthDescription)
          : props.produto.informacaoProduto}
        {props.produto.informacaoProduto &&
        props.produto.informacaoProduto.length > maxLengthDescription ? (
          <a className="card_info"><span>...</span></a>
        ) : (
          ""
        )}
      </span>
      <strong id="price">{formatPrice(props.produto.valor)}</strong>
    </div>
  );
}
