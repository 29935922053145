import React from "react";

import "./ListaCardsProdutos.css";

import Card from "../Card/Card";
import { Produto } from "../../interfaces/produto/Produto";

interface ListaCardsProdutosProps {
  produtos: Produto[];
  handleOpenModal: (isOpen: boolean, produto: Produto) => void;
  //event: React.ChangeEvent<HTMLImageElement>
}

export default function ListaCardsProdutos(props: ListaCardsProdutosProps) {
  function addDefaultSrc(ev: React.ChangeEvent<HTMLImageElement>) {
    const nome = ev.target.alt.replace(" ", "+");

    ev.target.src = `https://ui-avatars.com/api/?name=${nome}&background=fff&color=c00d1e&size=110`;
    ev.target.onerror = null;
  }

  return (
    <div className="productContainer">
      <div className="cardContent">
        <ul>
          {props.produtos.map(produto => (
            <li key={produto.id}>
              <Card produto={produto} click={props.handleOpenModal} />
              <img
                src={produto.src}
                onError={addDefaultSrc}
                alt={produto.nome}
                className="cardRoundImage"
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
