import React from "react";

import { FaAngleUp } from "react-icons/fa";

import "./Footer.css";

interface FooterProps {
  myRef: React.RefObject<HTMLDivElement>;
}
export default function Footer(props: FooterProps) {
  return (
    <div className="footer-container">
      <footer>
        <nav>
          <button
            onClick={() => {
              props.myRef.current?.scrollTo(0, 0);
            }}
            type="button"
            className="footer-button"
          >
            <span className="footer-event">TOPO</span>
            <FaAngleUp size={20} color="#FFF" />
          </button>
        </nav>
      </footer>
    </div>
  );
}
