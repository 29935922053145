import React, { useEffect } from 'react'

export const VerificarDominio = () => {

    useEffect(() => {
        let pathname = window.location.pathname;
        console.log(pathname)
        if (pathname.includes("b435067d-4f35-b287-53ae-1e5819d6cdc6")) {
            window.location.replace("https://delivery.meepfood.com/jurassicsafaribsb?listId=fffd99b3-319a-47ea-bef6-8bcac9c208bc&lojaId=b435067d-4f35-b287-53ae-1e5819d6cdc6");
        }

        if (pathname.includes("615fec29-5c8c-8f99-d431-55ec25f1aa26")) {
            window.location.replace("https://mepay.meep.cloud/mirantecasacor");
        }
        return () => {

        }
    }, [])

    return (
        {}
    )
}

