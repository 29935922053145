import React from 'react';

import './CategoriaLista.css';

import { Categoria } from '../../interfaces/Categoria/Categoria';

interface CategoriaProps {
  categoria: Categoria;
  click: (categoria: Categoria) => void;
}

export default function RoundImage(props: CategoriaProps) {

  function addDefaultSrc(ev: React.ChangeEvent<HTMLImageElement>) {
    const nome = ev.target.alt.replace(' ', '+');

    ev.target.src = `https://ui-avatars.com/api/?name=${nome}&background=fff&color=c00d1e&size=110`;
    ev.target.onerror = null;
  }

  const maxLengthName = 17;

  return (
    <div className="round-content">
      <div
        className={
          props.categoria.isActive
            ? 'round-container round-content-ative'
            : 'round-container'
        }
        onClick={() => props.click(props.categoria)}
      >
        <img
          src={props.categoria.src}
          onError={addDefaultSrc}
          alt={props?.categoria.nome}
          className="round-image"
          style={{
            objectFit: "cover"
          }}
        />
      </div>
      <div className="round-description">
        <span>{props.categoria?.nome?.substr(0, maxLengthName)}
          {props.categoria?.nome?.length > maxLengthName ? "..." : ""}
        </span>
      </div>
    </div>
  );
}
