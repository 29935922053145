import React from 'react';

import meepLoading from  "../../../res/imgs/loading.gif";
import './Loading.css';

export default function Loading() {
  return (
    <div className="loading-container">
        <img src={meepLoading} alt="Meep Loading" className="loading-gif"/>
    </div>
  );
}
