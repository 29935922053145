import React from "react";
import { FaSearch } from "react-icons/fa";

import "./Menu.css";

import CategoriaLista from "../CategoriaLista/CategoriaLista";
import { Categoria } from "../../interfaces/Categoria/Categoria";

interface ListaCategoriasProps {
  categorias: Categoria[];
  filterByCategory: (categoria: Categoria) => void;
  search: string;
  setSearch: (search: string) => void;
  nomeLocal: string;  
}

export default function Menu(props: ListaCategoriasProps) {
  
  return (
    <div className="menu-container" id="menuContainer">
      <div className="menuHeader">
        <span>Bem-vindo ao {props.nomeLocal}!</span>
        <p>Confira nossas promoções e bom apetite! :)</p>
      </div>
      <div className="menuContent">
        {props.categorias.map(categoria => {
          
          return (
            <CategoriaLista
              categoria={categoria}
              key={categoria.id}
              click={props.filterByCategory}
            />
          );
        })}
      </div>
      <div className="menu-search">
        <button type="button" className="menu-button">
          <FaSearch size={17} color="#FFF" className="menu-svg"></FaSearch>
        </button>
        <input
          type="text"
          value={props.search}
          className="menu-input"
          onChange={e => props.setSearch(e.target.value.substr(0, 20))}
        />
      </div>
    </div>
  );
}
