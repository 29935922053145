import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { ApiResponse } from '../interfaces/api/ApiResponse';

const axiosInstance: AxiosInstance = axios.create({
    // baseURL: "http://localhost/Promptus.Meep.Server/",
    // baseURL: "https://meepserver-dev.azurewebsites.net/",
    baseURL: 'https://app-api.meep.cloud/'
});


export async function get<TResponse>(endPoint: string, params?: any): Promise<ApiResponse<TResponse>> {
    let result: AxiosResponse<TResponse> = await axiosInstance.get<TResponse>(endPoint, { params: params })
    console.log("GET");
    let response: ApiResponse<TResponse> = { data: result?.data, status: result?.status }
    return (response)
}
export async function post<TRequest, TResponse>(endPoint: string, postObject: TRequest): Promise<ApiResponse<TResponse>> {
    console.log("POST");
    let result: AxiosResponse<TResponse> = await axiosInstance.post<TResponse>(endPoint, postObject)
    let response: ApiResponse<TResponse> = { data: result?.data, status: result?.status }
    return (response)
}