import React from "react";

import "./Modal.css";
import { FaChevronLeft } from "react-icons/fa";

import Logo from "../../../res/imgs/logo-completa-branca.png";
import { Produto } from "../../interfaces/produto/Produto";
import { formatPrice } from "../../../util/format";

interface ProdutoProps {
  produto: Produto;
  click: (isOpen: boolean) => void;
  modalOpen: boolean;
}

export default function Modal(props: ProdutoProps) {
  function addDefaultSrc(ev: React.ChangeEvent<HTMLImageElement>) {
    const nome = ev.target.alt.replace(" ", "+");
    ev.target.src = `https://ui-avatars.com/api/?name=${nome}&background=fff&color=c00d1e&size=110`;
    ev.target.onerror = null;
  }

  return (
    <div className={`${props.modalOpen ? "modal" : "modalFadeout"}`}>
      <header className="modal_header">
      <button className="btn_modal">
          <FaChevronLeft size={20} color="#FFF" onClick={() => props.click(false)} />
        </button>
        <img src={Logo} alt="Logo Meep" className="modal_logo" />
      
      </header>
      <div className="modal_content">
        <div className="modal_content_title">
          <img
            src={props.produto.src}
            onError={addDefaultSrc}
            alt={props.produto.nome}
            className="modal_content_image"
          />
          <strong className="modal_content_name">{props.produto.nome}</strong>
        </div>
        <div className="modal_content_body">
          <p>{props.produto.informacaoProduto}</p>
        </div>
      </div>
      <footer className="modal_footer">
        <span>{formatPrice(props.produto.valor)}</span>
      </footer>
    </div>
  );
}
