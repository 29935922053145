const isNullOrEmpty = (string: string) => {
    return string === null ||
        string === undefined ||
        string === '' ||
        string === ' ';

}

const status = {
    OK: 200,
    BadRequest: 400,
    NotFound: 404
}

export { isNullOrEmpty, status };