import React, { useEffect, useState } from "react";
import {
  Produto,
  RespostaProdutosComCache
} from "../components/interfaces/produto/Produto";

import { ApiResponse } from "../components/interfaces/api/ApiResponse";
import { Categoria } from "../components/interfaces/Categoria/Categoria";
import Header from "../components/Cardapio/Header/Header";
import Menu from "../components/Cardapio/Menu/Menu";
import ListaCardsProdutos from "../components/Cardapio/ListaCardsProdutos/ListaCardsProdutos";
import Loading from "../components/Cardapio/Loading/Loading";
import Footer from "../components/Cardapio/Footer/Footer";
import Modal from "../components/Cardapio/Modal/Modal";

import imgTodos from "../res/imgs/fastfood.jpg";

import "./CardapioPage.css";

import { isNullOrEmpty, status } from "../util/utils";
import { post } from "../components/services/ApiBase";
import Backdrop from "../components/Cardapio/Backdrop/Backdrop";

const params = window.location.pathname.split("/");

const LocalId = params[params.length - 1];
// var baseURL = "https://meepcustomblobdev.blob.core.windows.net/";
var baseURL = "https://meepcustomblob.blob.core.windows.net/";

export const CardapioPage = () => {
  const [products, setProducts] = useState<Produto[]>([]);
  const [fullProducts, setFullProducts] = useState<Produto[]>([]);
  const [produtoModal, setprodutoModal] = useState<Produto>({} as Produto);
  const [categories, setCategories] = useState<Categoria[]>([]);
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState<Categoria>();
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [nomeLocal, setNomeLocal] = useState("");

  var params = {
    LocalId: LocalId.toLowerCase()
  };

  const [localIdFromFirstProduct, setLocalIdFromFirstProduct] = useState("")

  useEffect(() => {
    products.map((item) => {
      setLocalIdFromFirstProduct(item.localClienteId);
    })
    return () => {
      
    }
  }, [products])

  useEffect(() => {

    async function getAllProducts() {
      const response: ApiResponse<RespostaProdutosComCache> = await post<
        any,
        any
      >("api/Produto/BuscarProdutosComCache", params);
      if (response.status === status.OK) {
        setNomeLocal(response.data.nomeLocal);
        response.data.produtos.forEach(produto => {
          produto.localId = produto.localClienteId;
          produto.src = getImageURL(produto);
          createCategoryIfNoExists(produto);
          setIsLoading(false);
        });
        setProducts(response.data.produtos);
        setFullProducts(response.data.produtos);
        setIsLoading(false);
      }
    }


    function createCategoryIfNoExists(product: Produto) {
      if (
        !categories.some(c => c.id === product.categoriaProdutoLocalClienteId)
      ) {
        if (!categories.some(c => c.id === "allCategories")) {
          var categoryAll: Categoria = {
            id: "allCategories",
            nome: "Todos produtos",
            isActive: false,
            version: 0,
            localId: localIdFromFirstProduct,
            src: "https://meepblob.blob.core.windows.net/clientes-profile/" + localIdFromFirstProduct + ".png"
          };
          categories.push(categoryAll);
        }
        var category: Categoria = {
          id: product.categoriaProdutoLocalClienteId,
          nome: product.categoriaProdutoLocalClienteNome,
          isActive: false,
          version: 0,
          localId: localIdFromFirstProduct,
          src: ""
        };
        setCategories([...categories, category]);
        categories.push(category);
      }
      categories.forEach(category => {
        category.src = getImageURLCategoria(category);
      });
    }

    function getImageURL(product: Produto) {
      var url = `${product.localClienteId}/${product.id}.jpg?v=${product.version}`;

      return (baseURL + url).toLowerCase();
    }

    function getImageURLCategoria(categoria: Categoria) {
      if (categoria.id === "allCategories") {
        return "https://meepblob.blob.core.windows.net/clientes-profile/" + localIdFromFirstProduct + ".png";
      } else {
        const url = `${localIdFromFirstProduct}/${categoria.id}.jpg?v=${categoria.version}`;
        return (baseURL + url).toLowerCase();
      }
    }

    
    if (!isNullOrEmpty(LocalId)) {
      getAllProducts();
    }
  }, [localIdFromFirstProduct]);

  useEffect(() => {
    if (!category || category?.id === "allCategories") {
      let filteredProducts = fullProducts.filter(product =>
        product.nome.toLowerCase().includes(search.toLowerCase())
      );

      setProducts(filteredProducts);
    } else {
      let filteredProducts = fullProducts.filter(
        p => p.categoriaProdutoLocalClienteId === category?.id
      );
      filteredProducts = filteredProducts.filter(product =>
        product.nome.toLowerCase().includes(search.toLowerCase())
      );
      setProducts(filteredProducts);
    }
  }, [search, category]);

  function filterByCategory(category: Categoria) {
    reloadCategoria(category);
    setCategory(category);
  }

  function reloadCategoria(category: Categoria) {
    categories.forEach(categoria => {
      if (categoria.id !== category.id) {
        categoria.isActive = false;
      } else {
        category.isActive = true;
      }
    });
  }

  function handleOpenModal(isOpen: boolean, produto: Produto) {
    setIsOpen(isOpen);
    setprodutoModal(produto);
  }

  function handleCloseModal() {
    setIsOpen(false);
  }

  var divRef = React.createRef<HTMLDivElement>();

  const content = isLoading ? (
    <div className="cardapioContainer">
      <Header />
      <Loading />
    </div>
  ) : (
    <>
      {isOpen && <Backdrop />}
      {isOpen && (
        <Modal
          produto={produtoModal}
          click={handleCloseModal}
          modalOpen={isOpen}
        />
      )}

      <div className="dashboardContainer" ref={divRef}>
        <div className="cardapioContainer">
          <Header />
          <Menu
            categorias={categories}
            filterByCategory={filterByCategory}
            search={search}
            setSearch={setSearch}
            nomeLocal={nomeLocal}
          />
          <ListaCardsProdutos
            produtos={products}
            handleOpenModal={handleOpenModal}
          />
          <Footer myRef={divRef} />
        </div>
      </div>
    </>
  );

  return content;
};
