import React from "react";
import { Route, BrowserRouter } from "react-router-dom";
import "./App.css";
import { CardapioPage } from "./public/CardapioPage";
import { VerificarDominio } from "./components/verificarDominio/VerificarDominio"

function App() {
  VerificarDominio();
  return (
    <BrowserRouter>
      <Route path={"/"} component={CardapioPage} />
    </BrowserRouter>
  );
}

export default App;
