import React from "react";

import "./Header.css";

import Logo from "../../../res/imgs/logo-completa-branca.png";

export default function Header() {
  return (
    <div className="header-container">
      <header>
        <nav>
          <img src={Logo} alt="Logo Meep" className="logo" />
        </nav>
      </header>
    </div>
  );
}
